<template>
  <div class="wechat">wechat init</div>
</template>

<script>
import { DropdownMenu, DropdownItem } from "vant";
import { Cascader } from "vant";
import { RadioGroup, Radio } from "vant";
import { ActionSheet } from "vant";
import { wechatInitApi } from "@/api/wechatInitApi.js";
import { flexQrcodeApi } from "@/api/flexQrcodeApi.js";
import { payApi } from "@/api/payApi.js";
export default {
  data() {
    return {
      redirectUrl: "",
    };
  },
  methods: {
    getCode() {
      let id = 2;
      if (this.$route.query && this.$route.query.id) {
        id = this.$route.query.id;
      }
      if (this.$route.query && this.$route.query.code) {
        const code = this.$route.query.code;
        wechatInitApi
          .getOpenIdUserInfo(code)
          .then((ret) => {
            console.log("openid", ret);
            if (ret.code == 0 && ret.data) {
              this.openId = ret.data.openid;
              flexQrcodeApi.wxQrcode(id, this.openId).then((ret) => {
                console.log("wxqrcode", ret);
                const item = ret.data;
                this.title = item.title;
                this.text = item.text;
                document.title = this.text;
                this.wechatId = item.weChat.wechatId;
                this.wechatUrl = item.weChat.url;
              });
            }
          })
          .catch((err) => {
            this.showCsWidget = true;
            this.csTitle = "获取微信参数出错";
          });
      } else {
        this.getBaseInfos();
      }
    },
    getBaseInfos() {
      const currentUrl = window.location.href;
      const url = encodeURIComponent(currentUrl);
      const urlWithCode =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        payApi.AppID +
        "&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect";
      window.location.href = urlWithCode; // 打开这个链接，你的url后面就会跟上code的参数
    },
  },
  mounted() {
    this.getCode();
  },
  watch: {},
};
</script>

<style lang="less" scoped></style>
